import React, { useState, useEffect } from 'react';
import { Layout, Form, Input, Button } from 'antd';
import { Formik } from 'formik';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { validateQRCodeAction } from '../../store/qrCodeVerfication/QRCode.actions.js';
import { loginEventAttendeeAction } from '../../store/auth/Auth.actions.js';
import { unwrapResult } from '@reduxjs/toolkit';

const { Content } = Layout;

const EventLogin = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { isQRCodeValid, error: qrCodeError } = useSelector(
    (state) => state.qrCode
  );
  const { error } = useSelector((state) => state.auth);

  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(null); // Zustand für Fehlermeldung

  useEffect(() => {
    const eventID = location.pathname.split('/')[2];
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    if (eventID && token) {
      dispatch(validateQRCodeAction({ eventID, token }));
    } else {
      navigate('/error');
    }
  }, [dispatch, location, navigate]);

  useEffect(() => {
    if (!isQRCodeValid && qrCodeError) {
      navigate('/error');
    }
  }, [isQRCodeValid, qrCodeError, navigate]);

  const handleLogin = async (credentials) => {
    setIsLoading(true);
    setLoginError(null); // Reset Fehlermeldung
    try {
      const actionResult = await dispatch(loginEventAttendeeAction(credentials));
      unwrapResult(actionResult);
      setIsLoading(false);

      // Anmeldung erfolgreich, weiterleiten
      const eventID = location.pathname.split('/')[2];
      const queryParams = new URLSearchParams(location.search);
      const token = queryParams.get('token');
      navigate(`/eventHome/${eventID}?token=${token}`);
    } catch (err) {
      setIsLoading(false);
      // Fehlerbehandlung: Fehlermeldung anzeigen und nicht weiterleiten
      console.error('Login failed', err);
      setLoginError('Invalid username. Please try again.');
    }
  };

  const loginSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
  });

  return (
    <Content style={styles.content}>
      <div style={styles.container}>
        {isQRCodeValid ? (
          <>
            <h2 style={styles.title}>Event Login</h2>
            <Formik
              initialValues={{ username: '' }}
              validationSchema={loginSchema}
              validateOnBlur
              onSubmit={async (values) => {
                const { username } = values;
                await handleLogin({ username });
              }}
            >
              {({ handleSubmit, handleChange, values, errors, touched }) => (
                <Form onFinish={handleSubmit} style={styles.form}>
                  <Form.Item
                    validateStatus={
                      errors.username && touched.username ? 'error' : ''
                    }
                    help={
                      errors.username && touched.username ? errors.username : ''
                    }
                  >
                    <Input
                      name="username"
                      placeholder="Username"
                      onChange={handleChange}
                      value={values.username}
                    />
                  </Form.Item>

                  {/* Display login error */}
                  {(error || loginError) && (
                    <div style={styles.error}>
                      {loginError || 'Invalid username. Please try again.'}
                    </div>
                  )}

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isLoading}
                      style={styles.button}
                    >
                      Log in
                    </Button>
                  </Form.Item>
                </Form>
              )}
            </Formik>
          </>
        ) : (
          <p>Verifying QR Code...</p>
        )}
      </div>
    </Content>
  );
};

const styles = {
  content: {
    backgroundColor: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '75vh',
  },
  container: {
    backgroundColor: 'transparent',
    padding: '40px',
    maxWidth: '400px',
    width: '100%',
    marginTop: '-100px',
  },
  title: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#D51067',
  },
  form: {
    width: '100%',
  },
  button: {
    width: '100%',
  },
  error: {
    color: 'red',
    marginBottom: '10px',
    textAlign: 'center',
  },
};

export default EventLogin;

// npm install axios
import axios from 'axios'

const API = axios.create({
  baseURL: 'http://backend:3001/api', // Interner Netzwerkname des Backends im Docker-Netzwerk
  headers: {
    'Content-Type': 'application/json',
  },
});

export default API;